/* src/index.css */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.App {
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .App {
    max-width: 600px;
  }
}

h1 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  h1 {
    font-size: 2rem;
  }
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

@media (min-width: 600px) {
  button {
    width: auto;
    margin: 0.5rem;
  }
}

.question-container {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
}

.results-container {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.score {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.chart-container {
  width: 100%;
  height: 300px;
  margin-top: 1rem;
}

.intro-container {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 0 auto;
}

.intro-container h1 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.intro-container p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.intro-container ul {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.intro-container li {
  margin-bottom: 0.5rem;
}

@media (min-width: 600px) {
  .intro-container h1 {
    font-size: 2rem;
  }
}

/* New styles for the Results component */
.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.results-table th, .results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.results-table th {
  background-color: #f2f2f2;
}

.action-buttons {
  margin-top: 20px;
}

.action-buttons button {
  margin-right: 10px;
}

.raw-results {
  margin-top: 20px;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
}

.raw-results pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.results-container button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.action-buttons {
  margin-top: 20px;
}

.action-buttons button {
  margin-right: 10px;
}